ul, #myUL {
  list-style-type: none;
  
}

#myUL {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.caret {
  /* cursor: pointer; */
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

.caretprimary::before {
    /* cursor: pointer; */
  content: "\25B6";
  color: black;
  display: inline-block;
  margin-right: 6px;
}

.caret::before {
    content: "\2601";
    color: black;
    display: inline-block;
    margin-right: 6px;
  }

.caret-down::before {
  -ms-transform: rotate(90deg); /* IE 9 */
  -webkit-transform: rotate(90deg); /* Safari */
  transform: rotate(90deg);  
}

.nested {
  display: none;
}

.active {
  display: block;
}