.patient-details-min-height
{
min-height: 600px;
}

.css-tlfecz-indicatorContainer {
    padding: 0px !important
}

.page-content {
    min-height: 550px !important;
}

.pagination-rounded .page-link {
    min-width: 32px !important;
    max-width: 40px !important;
    width: auto !important;
}