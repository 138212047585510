.body-print {
    padding: 20px !important;
}

/* .div-option-print {
} */


@media print {
    .table-print thead {
        background-color: rgba(0, 0, 0, .05) !important;
        -webkit-print-color-adjust: exact;
    }

    .mt-500 {
        margin-top: 500px !important;
    }

    .mt-400 {
        margin-top: 800px !important;
    }
}

body {

    font-size: 0.9rem !important;

}

div[size="A4"] {
    width: 21cm;
    height: 29.7cm;
}

div[size="A4"][layout="portrait"] {
    width: 29.7cm;
    height: 21cm;
}