
/* #neonShadow{
    width: 100%;
    border:none;
    height: 100%;
    border-radius: 2px;
    
    transition:0.3s;
    background-color:white;
    color: black !important;
    animation: glow 1s infinite ;
    transition:0.5s;
  } */
  
  
  
  /* #neonShadow:hover{
    transform:translateX(-20px)rotate(30deg); 
     border-radius:5px;
    background-color:#2d93ee;
    transition:0.5s; 
  } */
  
 
  /* @keyframes glow{
    0%{
    box-shadow: 5px 5px 20px rgb(93, 52, 168),-5px -5px 20px rgb(93, 52, 168);}
    
    50%{
    box-shadow: 5px 5px 20px rgb(81, 224, 210),-5px -5px 20px rgb(81, 224, 210)
    }
    100%{
    box-shadow: 5px 5px 20px rgb(93, 52, 168),-5px -5px 20px rgb(93, 52, 168)
    }
  } */
  @keyframes animate{
    0%{
      opacity: 0;
      font-weight: 600;
    
    }
    50%{
      opacity: 1.5;
      font-weight: 600;
    }
    100%{
      opacity: 0;
     font-weight: 600;
      
    }
  }
  .animationsmalltag
  {
    animation: animate 1.5s linear infinite;
    color: #ffff00;
  }
  
  