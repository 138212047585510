.hover-zoom {
    overflow: hidden;
  }
  

  .hover-zoom img:hover {
    transform: scale(1.05);
    
  }

  .conteudo {

    border:4px solid #0195d5;
      border-radius: 10px;
  
    }
  